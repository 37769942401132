import React from 'react';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import FunwooAPI, {
  JobEntity,
  ListingDetail,
  PageOfUserStory,
  StaticOptionEntity,
  UserStoryShowcaseEntity,
} from '@/swagger';
import { GetStaticProps, NextPage } from 'next';

import HomeApp from '@/src/components/UI/Home';

interface Props {
  listings: Array<ListingDetail>;
  jobs: Array<JobEntity>;
  conferenceOptions: Array<StaticOptionEntity>;
  userStories: Array<UserStoryShowcaseEntity>;
  banner: StaticOptionEntity;
}

const Home: NextPage<Props> = (props) => {
  return <HomeApp {...props} />;
};
export const getStaticProps: GetStaticProps<Props> = async ({ locale = '' }) => {
  const { data: listingData } = await FunwooAPI.listingApi.search({ paging: { page: 1, pageSize: 6 } });
  const { data: jobData } = await FunwooAPI.jobApi.findAll();
  const { data: conferenceOptions = [] } = await FunwooAPI.systemApi.findAllStaticOptionsByCategory({
    category: 'CONFERENCE_LOCATION',
  });
  const { data: userStories } = await FunwooAPI.userStory.getShowcases(PageOfUserStory.HOME);

  const { data: banner } = await FunwooAPI.systemApi.getBanner({ page: 'HOMEPAGE' });

  return {
    props: {
      ...(await serverSideTranslations(locale, ['common'])),
      listings: listingData.payload ?? [],
      jobs: jobData,
      conferenceOptions,
      userStories,
      banner,
    },
    revalidate: Number(process.env.NEXT_PUBLIC_REVALIDATE_INTERVAL),
  };
};
export default Home;
