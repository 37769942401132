import React, { ComponentProps, ReactElement, useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, SimpleGrid } from '@chakra-ui/react';
import Listing from '@/src/components/UI/Listing';
import WindowContext from '@/src/context/WindowContext';
import { CountryEnum, ListingListEntity } from '@/swagger';
import Image from 'next/image';
import classNames from 'classnames';
import { BuyHouseConsultantPopup } from '@/src/components/feature/ConsultantForm/ConsultantForm';
import { useRouter } from 'next/router';
import { searchParamParser } from '@/utils/searchParamParser';
import dynamic from 'next/dynamic';

const DynamicBuyHouseConsultantPopup = dynamic<ComponentProps<typeof BuyHouseConsultantPopup>>(
  () => import('@/src/components/feature/ConsultantForm/ConsultantForm').then((mod) => mod.BuyHouseConsultantPopup),
  {
    ssr: false,
  }
);

type Props = {
  data: Array<ListingListEntity | { sid: 'marketing' }>;
  onFavButtonPressCallBack?: (sid: string, isFav: boolean) => void;
};

export default function Listings({ data, onFavButtonPressCallBack }: Props): ReactElement {
  const { imageSizes } = useContext(WindowContext);
  return (
    <SimpleGrid columns={{ base: 1, sm: 2, md: 3 }} spacing={8}>
      {data?.map((l) =>
        l.sid === 'marketing' ? (
          <MarketingCard key={l.sid} />
        ) : (
          <Listing
            onFavButtonPressCallBack={onFavButtonPressCallBack}
            key={(l as ListingListEntity).sid}
            listing={l as ListingListEntity}
            sizes={imageSizes}
          />
        )
      )}
    </SimpleGrid>
  );
}

const MarketingCard = () => {
  const [width, setWidth] = useState<number>(0);
  const [showForm, setShowForm] = useState<boolean>(false);

  const ref = useRef<HTMLDivElement | null>(null);

  const router = useRouter();

  useEffect(() => {
    const calculatedWidth = () => {
      if (!ref.current) return;
      const { clientWidth } = ref.current;
      setWidth(clientWidth);
    };

    calculatedWidth();
    window.addEventListener('resize', calculatedWidth);
    return () => window.removeEventListener('resize', calculatedWidth);
  }, []);

  const triggerShowForm = useCallback(async () => {
    const country = searchParamParser(router.query.country);

    if ([CountryEnum.CA, CountryEnum.US].some((_country) => country?.includes(_country))) {
      await router.push('/abroad#contact-us');
    } else {
      setShowForm((prev) => !prev);
    }
  }, [router.query.country]);

  return (
    <React.Fragment>
      <DynamicBuyHouseConsultantPopup show={showForm} onClose={triggerShowForm} />
      <Box
        onClick={triggerShowForm}
        _hover={{
          filter: {
            md: 'drop-shadow(-2px -2px 8px rgba(0, 0, 0, 0.12)) drop-shadow(2px 2px 8px rgba(0, 0, 0, 0.12))',
          },
          cursor: 'pointer',
        }}
        filter={'drop-shadow(-2px -2px 4px rgba(0, 0, 0, 0.04)) drop-shadow(2px 2px 4px rgba(0, 0, 0, 0.04))'}
        ref={ref}
        className={'relative rounded-[0.25rem] overflow-hidden flex justify-center items-center'}
      >
        <div className={'absolute flex flex-col items-center z-10'}>
          <p
            className={classNames(
              'flex flex-col md:flex-row items-center',
              'py-2 mb-2',
              'text-2xl leading-8 text-white font-medium'
            )}
          >
            <span>針對您的需求找到</span>
            <span>最合適的房源</span>
          </p>
          <div className={'py-2 px-8 text-lg leading-8 bg-white font-medium'}>填寫購屋需求</div>
        </div>
        <Image
          src={'/buy/marketing.png'}
          width={width}
          height={(width / 3) * 2}
          objectFit={'cover'}
          className={'relative md:hidden md:invisible'}
        />
        <Image
          src={'/buy/marketing.png'}
          layout={'fill'}
          objectFit={'cover'}
          className={'relative hidden invisible md:block md:visible'}
        />
      </Box>
    </React.Fragment>
  );
};
